.teltonika-container {
    width: 100%;
    height: 100%;
    background-color: #000000;
    padding-top: 50px;
}

.cards-container {
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    padding: 64px 32px 0px 32px;
    display: flex;
    gap: 50px;
}

.routers-card {
    height: 545px;
}

.switches-card {
    height: 250px;
    width: 400px;
}

.gateway-card {
    height: 250px;
}

.routers-card:hover {
    transform: scale(1.05);
}


.switches-card:hover {
    transform: scale(1.05);
}


.gateway-card:hover {
    transform: scale(1.05);
}

.card {
    background-color: #292929;
    border-radius: 30px;
    padding: 30px;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.gate-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
}

.pictures {
    position: relative;
    width: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pictures-second {
    position: relative;
    width: 100%;
    height: 60%;
}

.router-1 {
    width: 100%;
    height: 100%;
    min-width: 500px;
}

.switch-gateway {
    position: absolute;
    width: 150px;
    height: 150px;
    top: -25px;
    left: 25%;
}

.info-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.info-title h1 {
    font-size: 36px;
}

.info-title p {
    font-size: 20px;
}

.cards-container-mobile {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 0px 30px;
}

@media only screen and (max-width: 1200px) {
    .cards-container {
        display: none;
        flex-direction: column;
        align-items: center;
    }

    .cards-container-mobile {
        display: flex;
    }

    .gate-switch-container {
        width: 100%;
        gap: 30px;
        margin-bottom: 30px;
    }

    .info-title h1 {
        font-size: 2em;
    }

    .info-title p {
        font-size: 1em;
    }
}