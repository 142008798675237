.navbar-container {
    background-color: black;
    color: #ffffff;
    padding: 22px 22px 44px 22px;
    display: flex;
    justify-content: space-between;
}

.logo {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 24px;
    cursor: pointer;
}

.nav-list {
    display: flex;
    gap: 60px;
    align-items: center;
}

.nav-content {
    display: flex;
    align-items: center;
    background-color: black;
}

.nav-list-mobile {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    gap: 60px;
    align-items: center;
    background-color: black;
}


.nav-list li {
    list-style-type: none;
    font-size: 20px;
    cursor: pointer;
}

.nav-list li:first-child div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.dropdown-container {
    background-color: #292929;
    color: white;
    font-size: 16px;
}

.dropdown-container ul {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dropdown-element {
    padding: 8px 60px 8px 9px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.dropdown-element:hover {
    background-color: #474747;
}

.dropdown-lang-element {
    padding: 8px 20px 8px 9px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.dropdown-lang-element:hover {
    background-color: #474747;
}

.language-change {
    display: flex;
    align-items: center;
}

.nav-elements-mobile {
    width: 100%;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .nav-content {
        display: none;
    }

    .language-change {
        display: none;
    }

}

@media only screen and (min-width: 1200px) {
    .nav-mobile {
        display: none;
    }

}