.dashboard-container {
    background-color: black;
    color: white;
    display: flex;
    padding-top: 40px;
}

.left-container {
    width: 50%;
    padding: 20px 65px;
}

.left-container h1 {
    font-weight: 400;
    font-size: 64px;
    padding-bottom: 65px;
    line-height: 90px;
}

.left-container p {
    color: #A1A1AA;
    font-size: 18px;
    width: 60%;
    min-width: 300px;
    line-height: 28px;
}

.right-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 65px;
    gap: 60px;
    font-size: 24px;
}

.right-container div {
    color: #71717A;
}

.search-container {
    padding: 130px 0px;
    width: 100%;
    min-width: 300px;
}

.searchButton {
    border-radius: 60px;
    height: 70px;
    width: 350px;
    font-size: 14px;
    font-weight: 700;
}

@media only screen and (max-width: 1200px) {
    .dashboard-container {
        flex-direction: column;
    }

    .left-container {
        width: 100%;
        padding: 22px;
    }

    .right-container {
        width: 100%;
        padding: 22px;
    }

    .left-container p {
        width: 100%;
    }
}